import { ApolloClient, InMemoryCache, createHttpLink, useQuery as apolloUseQuery, useLazyQuery as apolloUseLazyQuery } from '@apollo/client';
export { useApolloClient, useMutation } from '@apollo/client';
export const useQuery = (query, options) => {
  return apolloUseQuery(query, {
    ...options,
    ssr: false
  });
};
export const useLazyQuery = (query, options) => {
  return apolloUseLazyQuery(query, {
    ...options,
    ssr: false
  });
};
const sherlockGQLclient = new ApolloClient({
  link: createHttpLink({
    uri: process.env.sherlockGQL,
    credentials: 'include'
  }),
  cache: new InMemoryCache()
});
export const useSherlockQuery = (query, options) => useQuery(query, {
  ...options,
  client: sherlockGQLclient
});
export const useSherlockLazyQuery = (query, options) => useLazyQuery(query, {
  ...options,
  client: sherlockGQLclient
});